import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, Textarea, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import CharacterCertificateListTable from './tables/CharacterCertificateListTable';

export default function CreateCharacterCertificate() {
    const [token, settoken] = useState('')
    const [selectedExamName, setselectedExamName] = useState("")
    const [studentlist, setstudentlist] = useState([])
    const [selectedstudent, setselectedstudent] = useState(null);
    const [previewImage, setpreviewImage] = useState('')
    const [pdf_url, setpdf_url] = useState('')

    const handleCreateCertificate = async () => {

        const token = localStorage.getItem("admin_token");
        if (selectedstudent === null || pdf_url === '') {
            toast.error("Please Enter All Fields.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        else {
            const data = {
                student_id: selectedstudent?.value,
                pdf_url: pdf_url && pdf_url
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/charactercertificate', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };


        fetch(`https://smb-eta.vercel.app/admin/getStudentList?name&dob&classwithsection_id`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setstudentlist(data?.data);
                // settotalholidays(data?.totalRecords)
            }).catch(e => console.log(e))

    }


    const options = studentlist && studentlist.map(data => {
        return { value: data?.student?._id, label: `${data?.student?.name} - ${data?.classdata?.standard_name} ${data?.classdata?.standard_section}` }
    })

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        // setPostImage({ ...postImage, myFile: base64 })
        setpdf_url(base64)
    }

    // const handleFileUpload2 = async (e) => {
    //     const file = e.target.files[0];
    //     const base64 = await convertToBase64(file);
    //     // setPostImage({ ...postImage, myFile: base64 })
    //     setpdf_url(base64)
    // }

    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    return (
        <Container maxW="container.xlg" padding={4} marginBottom={4} overflowY={"auto"}>
            <Flex h="100vh" py={20}>
                <VStack w="50%" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading> Create Character Certificate</Heading>
                    <FormControl>
                        <FormLabel>Select Student</FormLabel>
                        <Select
                            // styles={{width: "100%"}}
                            options={options}
                            value={selectedstudent}
                            onChange={setselectedstudent}
                        />
                    </FormControl>

                    {/* <FormControl>
                        <FormLabel>Upload Certificate Image</FormLabel>
                        <Input type='file' onChange={handleFileUpload} />
                    </FormControl> */}

                    <FormControl>
                        <FormLabel>Upload Certificate Pdf </FormLabel>
                        <Input type='file' onChange={handleFileUpload} />
                    </FormControl>

                    <Button colorScheme='teal' variant='outline' onClick={handleCreateCertificate} p={2}>
                        Submit
                    </Button>
                </VStack>
                <VStack bg={'gray.200'} w="full" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading>Character Certificate Listing</Heading>
                    <CharacterCertificateListTable />
                </VStack>
            </Flex>
        </Container>
    )
}