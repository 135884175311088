import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Textarea, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ExamListTable from './tables/ExamListTable';
import ClassSectionListTable from './tables/ClassSectionListTable';
import EventListTable from './tables/EventlistTable';
import CreateAnnouncements from './CreateAnnouncements';
import CreateAcademicMonths from './CreateAcademicMonths';


export default function CreateSchoolData() {
    const [token, settoken] = useState('')
    const [schoolinfo, setschoolinfo] = useState({})
    const [selectedclassName, setselectedclassName] = useState("");
    const [section, setsection] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure()

    // create school info fields
    const [schoolname, setschoolname] = useState("")
    const [schoolmobile, setschoolmobile] = useState("")
    const [school_tel, setschool_tel] = useState("")
    const [principal_name, setprincipal_name] = useState("")
    const [principal_signature, setprincipal_signature] = useState("")
    const [school_address, setschool_address] = useState("")
    const [email, setemail] = useState("")
    const [latitude, setlatitude] = useState("")
    const [longitude, setlongitude] = useState("")
    const [admission_open, setadmission_open] = useState()
    const [terms_and_conditions_url, setterms_and_conditions_url] = useState("")

    const handleSchoolData = async () => {

        const token = localStorage.getItem("admin_token");

            const data = {
                name: schoolname && schoolname,
                school_mobile: schoolmobile && schoolmobile,
                tel: school_tel && school_tel,
                principal_name: principal_name && principal_name,
                principal_signature: principal_signature && principal_signature,
                school_address: school_address && school_address,
                email: email && email,
                latitude: latitude && latitude,
                longitude: longitude && longitude,
                admission_open: (admission_open === "true"? true : (admission_open === "false"? false: '')),
                terms_and_conditions_url: terms_and_conditions_url && terms_and_conditions_url
            }

            console.log({data})

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/addschoolinfo', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        
    }

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch(`https://smb-eta.vercel.app/admin/getschoolInfo`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setschoolinfo(data?.data);
                setschoolname(data?.data?.name);
                setschoolmobile(data?.data?.school_mobile);
                setschool_tel(data?.data?.tel);
                setprincipal_name(data?.data?.principal_name);
                setprincipal_signature(data?.data?.principal_signature);
                setschool_address(data?.data?.school_address);
                setemail(data?.data?.email);
                setlatitude(data?.data?.latitude);
                setlongitude(data?.data?.longitude);
                setadmission_open(data?.data?.admission_open)
                setterms_and_conditions_url(data?.data?.terms_and_conditions_url)
                // settotalholidays(data?.totalRecords)
            }).catch(e => console.log(e))

    }

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        console.log(base64)
        // setPostImage({ ...postImage, myFile: base64 })
        setprincipal_signature(base64)
    }

    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    return (
        <Container maxW="container.xlg" padding={4} marginBottom={4} overflowY={"auto"}>
            <Flex h="100vh" py={20}>
                <VStack w="30%" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading> School Info</Heading>

                    <Button colorScheme='blue' variant='outline' onClick={onOpen} p={2}>
                        Add / Update School Info
                    </Button>

                    <CreateAnnouncements />
                    <CreateAcademicMonths />
                </VStack>
                <VStack bg={'gray.200'} w="full" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading>School Events</Heading>
                    <EventListTable />
                </VStack>
            </Flex>

            {/* To create New Standard */}
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add / Update School Info</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody py={8}>
                        <FormControl pb={2}>
                            <FormLabel>School Name</FormLabel>
                            <Input type='text' placeholder='ABC Higher Secondary School.' value={schoolname} onChange={(e) => setschoolname(e.target.value)} />

                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>School Mobile</FormLabel>
                            <Input type='text' placeholder='e.g., 94191XXXX2345' value={schoolmobile} onChange={(e) => setschoolmobile(e.target.value)} />
                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>School Telephone</FormLabel>
                            <Input type='text' placeholder='e.g., 27189838' value={school_tel} onChange={(e) => setschool_tel(e.target.value)} />

                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>Principal Name</FormLabel>
                            <Input type='text' placeholder='Enter name' value={principal_name} onChange={(e) => setprincipal_name(e.target.value)} />

                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>Principal Signature</FormLabel>
                            <Input type='file' placeholder='Select file' onChange={handleFileUpload} />

                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>School Address</FormLabel>
                            <Input type='text' placeholder='Address here' value={school_address} onChange={(e) => setschool_address(e.target.value)} />

                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>School Email</FormLabel>
                            <Input type='text' placeholder='Enter email' value={email} onChange={(e) => setemail(e.target.value)} />

                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>School Latitude</FormLabel>
                            <Input type='text' placeholder='33.7723' value={latitude} onChange={(e) => setlatitude(e.target.value)} />

                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>School Longitude</FormLabel>
                            <Input type='text' placeholder='45.33453' value={longitude} onChange={(e) => setlongitude(e.target.value)} />

                        </FormControl>

                        <FormControl>
                            <FormLabel>Admission Status</FormLabel>
                            {/* <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} /> */}
                            <Select placeholder='Select Admission Status' value={admission_open} onChange={(e) => setadmission_open(e.target.value)}>
                                <option value={true} >Open</option>
                                <option value={false} >Closed</option>

                            </Select>
                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>Terms and Condition url</FormLabel>
                            <Input type='text' placeholder='Url here' value={terms_and_conditions_url} onChange={(e) => setterms_and_conditions_url(e.target.value)} />

                        </FormControl>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={handleSchoolData}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    )
}