import React, { useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Avatar,
    Box,
    IconButton,
    Tooltip,
    Image,
    Switch,

} from '@chakra-ui/react'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';



export default function StaffTable({ data}) {

    

    return (
        <Table variant='striped' colorScheme='' size={'lg'}>
            <Thead>
                <Tr>
                    <Th>S.No</Th>
                    <Th>Email</Th>
                    <Th>Staff Type</Th>
                    <Th>Active Status</Th>
                    {/* <Th>Actions</Th> */}
                </Tr>
            </Thead>
            <Tbody>
                {data?.length > 0 ? data?.map((item, i) => (
                    <Tr key={i}>
                        <Td>{i + 1}</Td>
                        <Td>{item?.email}</Td>
                        <Td>{item?.admin_type}</Td>
                        <Td>
                            <ToggleSwitch status={item.status} id={item?._id} />

                            
                        </Td>

                    </Tr>
                )) : <TableCaption>No Records Found!</TableCaption>}


            </Tbody>
        </Table>
    )
}

export const ToggleSwitch = ({status, id}) => {
    const token = localStorage.getItem("admin_token");
    const [isChecked, setIsChecked] = useState(status);

    const handleToggle = () => {
        const data = {
            staffid: id,
            newstatus: !status
        }

        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch('https://smb-eta.vercel.app/admin/updateStaffStatus', requestOptions)
            .then(resp => resp.json())
            .then(data => {
                if (data.status === 'ok') {
                    // setLoader(false)
                    toast.success(data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    // setLoader(false)
                    toast.warning(data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).catch(e => console.log(e))
        setIsChecked(!isChecked);
    };

    return (
        <div>
            <Switch id='isChecked' onChange={handleToggle} isChecked={isChecked} />
        </div>
    );
}
