import React, { useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Avatar,
    Box,
    IconButton,
    Tooltip,
    Image,
    Switch,

} from '@chakra-ui/react'
import 'react-toastify/dist/ReactToastify.css';

export default function ClassSectionListTable() {
    const [token, settoken] = useState('')
    const [data, setdata] = useState([])
    const [totalrecords, settotalrecords] = useState(0)
    
    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };


        fetch(`https://smb-eta.vercel.app/admin/classSectionListing?standard_id=`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setdata(data?.data);
                settotalrecords(data?.totalRecords)
            }).catch(e => console.log(e))

    }
    console.log({ data })
    return (
        <Table variant='striped' colorScheme='' size={'lg'}>
            <Thead>
                <Tr>
                    <Th>S.No</Th>
                    <Th>Class Name</Th>
                    <Th>Section</Th>
                    
                    {/* <Th>Actions</Th> */}
                </Tr>
            </Thead>
            <Tbody>
                {data?.length > 0 ? data?.map((item, i) => (
                    <Tr key={i}>
                        <Td>{i + 1}</Td>
                        <Td>{item?.standard_name}</Td>
                        <Td>{item?.standard_section}</Td>
                    </Tr>
                )) : <TableCaption>No Records Found!</TableCaption>}


            </Tbody>
        </Table>
    )
}

