import React, { useEffect, useState } from 'react';
import { TableContainer, useDisclosure, Button, Text, Heading, TabPanels, TabPanel, Tab, Tabs, TabList, useColorModeValue, Input, HStack, VStack, FormControl, FormLabel, FormHelperText } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import ApplicationFormTable from '../tables/ApplicationFormTable';
import ReactPaginate from 'react-paginate';

export default function ApplicationFormList() {
    const colors = useColorModeValue(
        ['blue.50', 'teal.50', 'red.50'],
        ['blue.900', 'teal.900', 'red.900'],
    )
    const [tabIndex, setTabIndex] = useState(0)
    const bg = colors[tabIndex]
    const [token, settoken] = useState('')

    // PENDING STATES
    const [pendingdata, setpendingdata] = useState([])
    const [totalpending, settotalpending] = useState(0)
    const [itemOffset_p, setItemOffset_p] = useState(1);
    const [itemsPerPage_p, setitemsPerPage_p] = useState(10);
    const pageCount_p = Math.ceil(totalpending / itemsPerPage_p);

    // APPROVED STATES
    const [approveddata, setapproveddata] = useState([])
    const [totalapproved, settotalapproved] = useState(0)
    const [itemOffset_a, setItemOffset_a] = useState(1);
    const [itemsPerPage_a, setitemsPerPage_a] = useState(10);
    const pageCount_a = Math.ceil(totalapproved / itemsPerPage_a);

    // REJECTED STATES
    const [rejecteddata, setrejecteddata] = useState([])
    const [totalrejected, settotalrejected] = useState(0)
    const [itemOffset_r, setItemOffset_r] = useState(1);
    const [itemsPerPage_r, setitemsPerPage_r] = useState(10);
    const pageCount_r = Math.ceil(totalrejected / itemsPerPage_r);

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }
        else {
            window.location.href = "/login";
        }

        async function FetchData() {
            var requestOptions = {
                method: 'GET',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            //Get Pending forms
            fetch(`https://smb-eta.vercel.app/admin/applicationforms?from&till&page=${itemOffset_p}&limit=${itemsPerPage_p}&isApplicationApproved=false&mobile&isApplicationProcessed=false`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setpendingdata(data?.data);
                    settotalpending(data?.totalRecords)
                }).catch(e => console.log(e))

            //Get Approved forms
            fetch(`https://smb-eta.vercel.app/admin/applicationforms?from&till&page=${itemOffset_a}&limit=${itemsPerPage_a}&isApplicationApproved=true&mobile&isApplicationProcessed=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setapproveddata(data?.data);
                    console.log({ data })
                    settotalapproved(data?.totalRecords)

                }).catch(e => console.log(e))

            //Get Rejected forms
            fetch(`https://smb-eta.vercel.app/admin/applicationforms?from&till&page=${itemOffset_r}&limit=${itemsPerPage_r}&isApplicationApproved=false&mobile&isApplicationProcessed=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setrejecteddata(data?.data);
                    settotalrejected(data?.totalRecords)

                }).catch(e => console.log(e))

        }

        FetchData();

    }, [])

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        async function FetchData() {
            var requestOptions = {
                method: 'GET',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            //Get Approved forms
            fetch(`https://smb-eta.vercel.app/admin/applicationforms?from&till&page=${itemOffset_a}&limit=${itemsPerPage_a}&isApplicationApproved=true&mobile&isApplicationProcessed=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setapproveddata(data?.data);
                }).catch(e => console.log(e))
        }

        FetchData();

    }, [itemOffset_a])

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        async function FetchData() {
            var requestOptions = {
                method: 'GET',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            //Get Pending forms
            fetch(`https://smb-eta.vercel.app/admin/applicationforms?from&till&page=${itemOffset_p}&limit=${itemsPerPage_p}&isApplicationApproved=false&mobile&isApplicationProcessed=false`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setpendingdata(data?.data);
                }).catch(e => console.log(e))
        }

        FetchData();

    }, [itemOffset_p])

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        async function FetchData() {
            var requestOptions = {
                method: 'GET',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            //Get Rejected forms
            fetch(`https://smb-eta.vercel.app/admin/applicationforms?from&till&page=${itemOffset_r}&limit=${itemsPerPage_r}&isApplicationApproved=false&mobile&isApplicationProcessed=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setrejecteddata(data?.data);
                }).catch(e => console.log(e))
        }

        FetchData();

    }, [itemOffset_r])

    const handlePageClick_a = (event) => {
        const newOffset = (event.selected % totalapproved) + 1;
        setItemOffset_a(newOffset);
    };

    const handlePageClick_p = (event) => {
        const newOffset = (event.selected % totalpending) + 1;
        setItemOffset_p(newOffset);
    };

    const handlePageClick_r = (event) => {
        const newOffset = (event.selected % totalrejected) + 1;
        setItemOffset_r(newOffset);
    };

    return (
        <TableContainer >
            <Heading>Application Forms List</Heading>

            <Tabs onChange={(index) => setTabIndex(index)} bg={bg} padding={4}>
                <TabList>
                    <Tab>Pending</Tab>
                    <Tab>Approved</Tab>
                    <Tab>Disapproved</Tab>
                </TabList>
                <TabPanels p='2rem'>
                    <TabPanel>
                        <ApplicationFormTable data={pendingdata && pendingdata} onOpen={onOpen} />
                        <ul className="mc-paginate-list pt-2">
                            <nav aria-label="Page navigation example">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=" >"
                                    onPageChange={handlePageClick_p}
                                    pageRangeDisplayed={3}
                                    pageCount={pageCount_p}
                                    previousLabel="< "
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination justify-content-start"
                                    pageLinkClassName="mc-paginate-item"
                                    previousLinkClassName="mc-paginate-item"
                                    nextLinkClassName="mc-paginate-item"
                                    activeLinkClassName="mc-paginate-item active"
                                    breakLinkClassName="mc-paginate-item"
                                />

                            </nav>
                        </ul>
                    </TabPanel>
                    <TabPanel>
                        <ApplicationFormTable data={approveddata && approveddata} onOpen={onOpen} />
                        <ul className="mc-paginate-list pt-2">
                            <nav aria-label="Page navigation example">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=" >"
                                    onPageChange={handlePageClick_a}
                                    pageRangeDisplayed={3}
                                    pageCount={pageCount_a}
                                    previousLabel="< "
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination justify-content-start"
                                    pageLinkClassName="mc-paginate-item"
                                    previousLinkClassName="mc-paginate-item"
                                    nextLinkClassName="mc-paginate-item"
                                    activeLinkClassName="mc-paginate-item active"
                                    breakLinkClassName="mc-paginate-item"
                                />

                            </nav>
                        </ul>
                    </TabPanel>
                    <TabPanel>
                        <ApplicationFormTable data={rejecteddata && rejecteddata} onOpen={onOpen} />
                        <ul className="mc-paginate-list pt-2">
                            <nav aria-label="Page navigation example">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=" >"
                                    onPageChange={handlePageClick_r}
                                    pageRangeDisplayed={3}
                                    pageCount={pageCount_r}
                                    previousLabel="< "
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination justify-content-start"
                                    pageLinkClassName="mc-paginate-item"
                                    previousLinkClassName="mc-paginate-item"
                                    nextLinkClassName="mc-paginate-item"
                                    activeLinkClassName="mc-paginate-item active"
                                    breakLinkClassName="mc-paginate-item"
                                />

                            </nav>
                        </ul>
                    </TabPanel>
                </TabPanels>
            </Tabs>



            {/* Modal from here */}
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontWeight='bold' mb='1rem'>
                            You can scroll the content behind the modal
                        </Text>
                        {/* <Lorem count={2} /> */}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </TableContainer>
    )
}