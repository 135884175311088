import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, HStack, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Textarea, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ExamListTable from './tables/ExamListTable';
import ClassSectionListTable from './tables/ClassSectionListTable';

export default function CreateAcademicMonths() {
    const [token, settoken] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()

    // create standard fields
    const [classdata, setclassdata] = useState([])
    const [selectedclassName, setselectedclassName] = useState("");
    const [academicmonths, setacademicmonths] = useState('')
    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch(`https://smb-eta.vercel.app/admin/getStandardnames`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setclassdata(data?.data);
                // settotalholidays(data?.totalRecords)
            }).catch(e => console.log(e))


    }


    const handleCreateAcademicMonths = async () => {
        const token = localStorage.getItem("admin_token");
        if (selectedclassName === '' || academicmonths === '') {
            toast.error("Please Enter All Fields.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if (isNaN(academicmonths) === true) {
            toast.error("Months must be in integer.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if (parseInt(academicmonths) <= 0 || parseInt(academicmonths) > 12) {
            toast.error("Academic Months must be between 0 and 12.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {


            const data = {
                standard_id: selectedclassName,
                number_of_academic_months: parseInt(academicmonths)
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/academicMonthsForClass', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))

        }
    }

    return (
        <>
            <Button colorScheme='blue' variant='outline' onClick={onOpen} p={2}>
                Add Academic Months For Class
            </Button>
            {/* To create New subject */}
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Academic Months For Class</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody py={8}>
                        <FormControl>
                            <FormLabel>Class</FormLabel>
                            {/* <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} /> */}
                            <Select placeholder='Select Class' onChange={(e) => setselectedclassName(e.target.value)}>
                                {classdata && classdata.map(name => (
                                    <option value={name?._id} key={name?._id}>{name?.standard_name}</option>
                                ))}

                            </Select>
                        </FormControl>

                        <FormControl mt={3}>
                            <FormLabel>Academic Months</FormLabel>
                            <Input type='text' placeholder='e.g., 12 ' value={academicmonths} onChange={(e) => setacademicmonths(e.target.value)} />
                        </FormControl>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={handleCreateAcademicMonths}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}