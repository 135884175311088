import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, Select, Textarea, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StaffTable from './tables/StaffTable';
import HolidayTable from './tables/HolidayTable';


export default function CreateHoliday() {
    const [token, settoken] = useState('')
    const [name, setname] = useState('')
    const [description, setdescription] = useState('')
    const [holiday_date, setholiday_date] = useState('')

    const [data, setdata] = useState([])
    const [totalholidays, settotalholidays] = useState(0)

    const handleCreateHoliday = async () => {

        const token = localStorage.getItem("admin_token");
        if (name === '' || holiday_date === '') {
            toast.error("Please Enter Holiday name and date.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            const data = {
                date: holiday_date,
                name,
                description
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/createholiday', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setname('')
                        setdescription('')
                        setholiday_date('')
                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };


        fetch(`https://smb-eta.vercel.app/admin/holidaylist?from&till&page&limit`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setdata(data?.data);
                settotalholidays(data?.totalRecords)
            }).catch(e => console.log(e))
    }
    return (
        <Container maxW="container.xlg" padding={4} marginBottom={4}  overflowY={"auto"}>
            <Flex h="100vh" py={20}>
                <VStack w="40%" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading> Create Holiday</Heading>
                    <FormControl>
                        <FormLabel>Holiday Name</FormLabel>
                        <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Textarea placeholder='Here is a sample placeholder' value={description} onChange={(e) => setdescription(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Date</FormLabel>
                        <Input
                            placeholder="Select Date and Time"
                            size="md"
                            type="datetime-local"
                            value={holiday_date} onChange={(e) => setholiday_date(e.target.value)}
                        />
                    </FormControl>

                    <Button colorScheme='teal' variant='outline' onClick={handleCreateHoliday}>
                        Create Holiday
                    </Button>
                </VStack>
                <VStack bg={'gray.200'} w="full" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading>Holiday Listing</Heading>
                    <HolidayTable data={data && data} />
                </VStack>
            </Flex>
        </Container>
    )
}