import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, HStack, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Textarea, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ExamListTable from './tables/ExamListTable';
import ClassSectionListTable from './tables/ClassSectionListTable';


export default function CreateSubject() {
    const [token, settoken] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()

    // create standard fields
    const [subject_name, setsubject_name] = useState("")

    // useEffect(() => {
    //     const token = localStorage.getItem("admin_token");
    //     if (token) {
    //         settoken(token);
    //     }

    //     FetchData();
    // }, [])

    // async function FetchData() {
    //     const token = localStorage.getItem("admin_token");
    //     var requestOptions = {
    //         method: 'GET',
    //         headers: {
    //             'Accept': '*/*',
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': '*',
    //             'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    //             'Access-Control-Allow-Headers': 'Content-Type',
    //             'Authorization': "Bearer " + token,
    //         },
    //     };

    //     fetch(`https://smb-eta.vercel.app/admin/getStandardnames`, requestOptions)
    //         .then(response => response.json())
    //         .then(data => {
    //             setclassdata(data?.data);
    //             // settotalholidays(data?.totalRecords)
    //         }).catch(e => console.log(e))

    // }


    const handleCreateSubject = async () => {

        const token = localStorage.getItem("admin_token");
        if (subject_name === '') {
            toast.error("Subject Name is required.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {

            const data = {
                subject_name
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/createSubject', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        }
    }

    return (
        <>
            <Button colorScheme='blue' variant='outline' onClick={onOpen} p={2}>
                Create Subject
            </Button>
            {/* To create New subject */}
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create A New Subject</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody py={8}>
                        <FormControl pb={2}>
                            <FormLabel>Subject Name</FormLabel>
                            <Input type='text' placeholder='Enter name' value={subject_name} onChange={(e) => setsubject_name(e.target.value)} />

                        </FormControl>
    
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={handleCreateSubject}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}