import { Avatar, CSSReset, Divider, Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import { BrowserRouter, Route, NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import {
    FiBriefcase,
    FiCalendar,
    FiDollarSign,
    FiHome,
    FiMenu,
    FiSettings,
    FiUser
} from 'react-icons/fi'
import logo from "../logo.svg"
import NavItem from './NavItem';

export default function Sidebar() {
    const [navSize, changeNavSize] = useState("large")
    return (

        <Flex
            pos="sticky"
            left="5"
            h="95vh"
            marginTop={"2.5vh"}
            boxShadow="0 4px 12px 0 rgba(0, 0,0, 0.05)"
            borderRadius={navSize == "small" ? "15px" : "30px"}
            w={navSize == "small" ? "75px" : "200px"}
            flexDir="column"
            justifyContent={"space-between"}
            marginRight={"10"}
        >
            <Flex
                p={"5%"}
                flexDir="column"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                as="nav"
                overflowY={"auto"}
            >
                <CSSReset styles={` ::-webkit-scrollbar {
                        display: none;
                        }
                    `} />
                <IconButton
                    background="none"
                    mt={5}
                    _hover={{ background: 'none' }}
                    icon={<FiMenu />}
                    onClick={() => {
                        if (navSize === "small")
                            changeNavSize("large")
                        else
                            changeNavSize("small")
                    }}
                />

                <NavItem navSize={navSize} icon={FiHome} title="Applications" activeClassName="active-nav" to="/home" />
                {/* <NavItem navSize={navSize} icon={FiCalendar} title="Staff" activeClassName="active-nav" to="/createStaff" /> */}
                <NavItem navSize={navSize} icon={FiCalendar} title="Teachers" activeClassName="active-nav" to="/createTeacher" />
                <NavItem navSize={navSize} icon={FiUser} title="Holiday" activeClassName="active-nav" to="/createHoliday" />
                <NavItem navSize={navSize} icon={FiDollarSign} title="Exam" activeClassName="active-nav" to="/createExam" />
                <NavItem navSize={navSize} icon={FiBriefcase} title="Class" activeClassName="active-nav" to="/createClassSection" />
                <NavItem navSize={navSize} icon={FiSettings} title="TimeTable" activeClassName="active-nav" to="/createTimeTable" />
                <NavItem navSize={navSize} icon={FiCalendar} title="SchoolData" activeClassName="active-nav" to="/schoolData" />
                <NavItem navSize={navSize} icon={FiCalendar} title="Gallery" activeClassName="active-nav" to="/createGallery" />
                <NavItem navSize={navSize} icon={FiCalendar} title="Character Certificate" activeClassName="active-nav" to="/createCharacterCertificate" />

            </Flex>

            <Flex
                p="5%"
                flexDir={"column"}
                w={"100%"}
                alignItems={navSize == "small" ? "center" : "flex-start"}
                mb={4}
            >
                <Divider display={navSize == "small" ? "none" : "flex"} />
                <Flex mt={4} align={"center "}>
                    <Avatar size={"sm"} src={logo} />
                    <Flex flexDir={"column"} ml={"4"} display={navSize == "small" ? "none" : "flex"}>
                        <Heading as="h3" size="sm">Pratyaksh Gupta</Heading>
                        <Text color="gray">Admin</Text>
                    </Flex>
                </Flex>

            </Flex>

        </Flex>
    )
}