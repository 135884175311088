import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, Select, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StaffTable from './tables/StaffTable';


export default function CreateStaff() {
    const [token, settoken] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [type, settype] = useState('')

    const [data, setdata] = useState([])
    const [totalstaff, settotalstaff] = useState(0)

    const handleCreateStaff = async () => {
        const token = localStorage.getItem("admin_token");
        if (email === '' || password === '' || type === '') {
            toast.error("Please Enter all fields.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            const data = {
                email,
                password,
                type
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/createStaff', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setemail('')
                        setpassword('')
                        settype('')
                        FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };


        fetch(`https://smb-eta.vercel.app/admin/stafflist?email&status&admin_type=&page&limit`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setdata(data?.data);
                settotalstaff(data?.totalRecords)
            }).catch(e => console.log(e))
    }
    return (
        <Container maxW="container.xlg" padding={4} marginBottom={4}>
            <Flex h="100vh" py={20}>
                <VStack w="40%" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading> Create Staff</Heading>
                    <FormControl>
                        <FormLabel>Email address</FormLabel>
                        <Input type='email' value={email} onChange={(e) => setemail(e.target.value)} />
                        <FormHelperText>We'll never share your email.</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Password</FormLabel>
                        <Input
                            pr='4.5rem'
                            type={'text'}
                            placeholder='Enter password'
                            value={password} onChange={(e) => setpassword(e.target.value)}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Type</FormLabel>
                        <Select placeholder='Select Staff Type' onChange={(e) => settype(e.target.value)}>
                            <option value={'principal'}>Principal</option>
                            <option value={'teacher'}>Teacher</option>
                        </Select>
                    </FormControl>

                    <Button colorScheme='teal' variant='outline' onClick={handleCreateStaff}>
                        Create Staff
                    </Button>
                </VStack>
                <VStack bg={'gray.200'} w="full" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading>Staff Listing</Heading>
                    <StaffTable data={data && data} />
                </VStack>
            </Flex>
        </Container>
    )
}