import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, HStack, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Textarea, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ExamListTable from './tables/ExamListTable';
import ClassSectionListTable from './tables/ClassSectionListTable';
import CreateSubject from './CreateSubject';


export default function CreateClassSection() {
    const [token, settoken] = useState('')
    const [classdata, setclassdata] = useState([])
    const [selectedclassName, setselectedclassName] = useState("");
    const [section, setsection] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure()

    // create standard fields
    const [standard_name, setstandard_name] = useState("")
    const [standard_fees, setstandard_fees] = useState("")
    const [start_month, setstart_month] = useState("")
    const [end_month, setend_month] = useState("")

    const handleCreateClassSection = async () => {

        const token = localStorage.getItem("admin_token");
        if (section === '' || selectedclassName === '') {
            toast.error("Please Enter All Fields.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {

            const data = {
                standard_id: selectedclassName && selectedclassName,
                standard_section: section && section
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/createClassandSection', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch(`https://smb-eta.vercel.app/admin/getStandardnames`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setclassdata(data?.data);
                // settotalholidays(data?.totalRecords)
            }).catch(e => console.log(e))

    }


    const handleCreateStandard = async () => {

        const token = localStorage.getItem("admin_token");
        if (standard_name === '' || standard_fees === '' || start_month === '' || end_month === '') {
            toast.error("Please Enter All Fields.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {

            const data = {
                standard_name: standard_name && standard_name,
                standard_fees_amount: standard_fees && parseFloat(standard_fees),
                standard_start_month: start_month && start_month,
                standard_end_month: end_month && end_month
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/createStandard', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        }
    }

    return (
        <Container maxW="container.xlg" padding={4} marginBottom={4}  overflowY={"auto"}>
            <Flex h="100vh" py={20}>
                <VStack w="50%" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading> Create Class & Section</Heading>
                    <HStack>
                        <Button colorScheme='blue' variant='outline' onClick={onOpen} p={2}>
                            Create New Class
                        </Button>

                        <CreateSubject />
                        
                    </HStack>
                    <FormControl>
                        <FormLabel>Class</FormLabel>
                        {/* <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} /> */}
                        <Select placeholder='Select Class' onChange={(e) => setselectedclassName(e.target.value)}>
                            {classdata && classdata.map(name => (
                                <option value={name?._id} key={name?._id}>{name?.standard_name}</option>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Enter Section</FormLabel>
                        <Input type='text' placeholder='e.g., A ' value={section} onChange={(e) => setsection(e.target.value)} />
                    </FormControl>

                    <Button colorScheme='teal' variant='outline' onClick={handleCreateClassSection} p={2}>
                        Create Class Section
                    </Button>
                </VStack>
                <VStack bg={'gray.200'} w="full" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading>Class & Section Listing</Heading>
                    <ClassSectionListTable />
                </VStack>
            </Flex>

            {/* To create New Standard */}
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create A New Class</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody py={8}>
                        <FormControl pb={2}>
                            <FormLabel>Class Name</FormLabel>
                            <Input type='text' placeholder='Enter name' value={standard_name} onChange={(e) => setstandard_name(e.target.value)} />

                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>Fees Amount (Monthly.)</FormLabel>
                            <Input type='text' placeholder='e.g., 3000' value={standard_fees} onChange={(e) => setstandard_fees(e.target.value)} />
                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>Class Start Month</FormLabel>
                            {/* <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} /> */}
                            <Select placeholder='Select start Month' onChange={(e) => setstart_month(e.target.value)}>
                                <option value={"1"}>January</option>
                                <option value={"2"}>February</option>
                                <option value={"3"}>March</option>
                                <option value={"4"}>April</option>
                                <option value={"5"}>May</option>
                                <option value={"6"}>June</option>
                                <option value={"7"}>July</option>
                                <option value={"8"}>August</option>
                                <option value={"9"}>September</option>
                                <option value={"10"}>October</option>
                                <option value={"11"}>November</option>
                                <option value={"12"}>December</option>

                            </Select>
                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>Class End Month</FormLabel>
                            {/* <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} /> */}
                            <Select placeholder='Select start Month' onChange={(e) => setend_month(e.target.value)}>
                                <option value={"1"}>January</option>
                                <option value={"2"}>February</option>
                                <option value={"3"}>March</option>
                                <option value={"4"}>April</option>
                                <option value={"5"}>May</option>
                                <option value={"6"}>June</option>
                                <option value={"7"}>July</option>
                                <option value={"8"}>August</option>
                                <option value={"9"}>September</option>
                                <option value={"10"}>October</option>
                                <option value={"11"}>November</option>
                                <option value={"12"}>December</option>

                            </Select>
                        </FormControl>
                        {/* <Lorem count={2} /> */}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={handleCreateStandard}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Container>
    )
}