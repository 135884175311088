import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, HStack, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Textarea, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ExamListTable from './tables/ExamListTable';
import ClassSectionListTable from './tables/ClassSectionListTable';

export default function CreateAnnouncements() {
    const [token, settoken] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()

    // create standard fields
    const [data, setdata] = useState({})
    const [a1, seta1] = useState("")
    const [a2, seta2] = useState("")
    const [a3, seta3] = useState("")

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch(`https://smb-eta.vercel.app/admin/getAnnouncementList`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setdata(data?.data);
                seta1(data?.data?.announcement_1)
                seta2(data?.data?.announcement_2)
                seta3(data?.data?.announcement_3)
                // settotalholidays(data?.totalRecords)
            }).catch(e => console.log(e))

    }


    const handleCreateAnnouncements = async () => {
        const token = localStorage.getItem("admin_token");

        const data = {
            a1: a1 && a1,
            a2: a2 && a2,
            a3: a3 && a3
        }

        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch('https://smb-eta.vercel.app/admin/addanouncement', requestOptions)
            .then(resp => resp.json())
            .then(data => {
                if (data.status === 'ok') {
                    // setLoader(false)
                    toast.success(data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    // FetchData()
                } else {
                    // setLoader(false)
                    toast.warning(data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).catch(e => console.log(e))

    }

    return (
        <>
            <Button colorScheme='blue' variant='outline' onClick={onOpen} p={2}>
                Add / Update Announcements
            </Button>
            {/* To create New subject */}
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add / Update an Announcement</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody py={8}>
                        <FormControl pb={2}>
                            <FormLabel>Announcement 1</FormLabel>
                            <Textarea value={a1 && a1} onChange={e => seta1(e.target.value)} placeholder='Announcement 1 here' />
                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>Announcement 2</FormLabel>
                            <Textarea value={a2 && a2} onChange={e => seta2(e.target.value)} placeholder='Announcement 2 here' />
                        </FormControl>

                        <FormControl pb={2}>
                            <FormLabel>Announcement 3</FormLabel>
                            <Textarea value={a3 && a3} onChange={e => seta3(e.target.value)} placeholder='Announcement 3 here' />
                        </FormControl>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={handleCreateAnnouncements}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}