import React, { useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Avatar,
    Box,
    IconButton,
    Tooltip,
    Image,
    Switch,
    Button,
    useDisclosure,
    Container,
    ModalFooter,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    FormControl,
    FormLabel,

} from '@chakra-ui/react'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';


export default function EventListTable() {
    const [token, settoken] = useState('')
    const [data, setdata] = useState([])
    const [totalrecords, settotalrecords] = useState(0)

    const { isOpen, onOpen, onClose } = useDisclosure()

    // event related fields
    const [heading, setheading] = useState('')
    const [description, setdescription] = useState('')
    const [image_link, setimage_link] = useState('')
    const [event_date, setevent_date] = useState('')

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };


        fetch(`https://smb-eta.vercel.app/admin/getEventlist`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setdata(data?.data);
                settotalrecords(data?.totalRecords)
            }).catch(e => console.log(e))

    }

    const handleSchoolEvents = async () => {

        const token = localStorage.getItem("admin_token");

        const data = {
            heading: heading && heading,
            description: description && description,
            image_link: image_link && image_link,
            date: event_date && event_date
        }

        console.log({ data })

        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch('https://smb-eta.vercel.app/admin/createevents', requestOptions)
            .then(resp => resp.json())
            .then(data => {
                if (data.status === 'ok') {
                    // setLoader(false)
                    toast.success(data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    // FetchData()
                } else {
                    // setLoader(false)
                    toast.warning(data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).catch(e => console.log(e))

    }

    function showFirstFewWords(str, numWords) {
        // Split the string into an array of words
        var words = str.split(' ');
      
        // Extract the desired number of words
        var extractedWords = words.slice(0, numWords);
      
        // Join the extracted words back into a string
        var result = extractedWords.join(' ');
      
        return result;
      }

      const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        console.log(base64)
        // setPostImage({ ...postImage, myFile: base64 })
        setimage_link(base64)
    }

    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    return (
        <>
            <Button colorScheme='blue' variant='outline' onClick={onOpen} p={2}>
                Add Event
            </Button>
            <Table variant='striped' colorScheme='' size={'lg'}>

                <Thead>
                    <Tr>
                        <Th>S.No</Th>
                        <Th>Heading</Th>
                        <Th>Description</Th>
                        <Th>Image</Th>
                        <Th>Event Date</Th>

                        {/* <Th>Actions</Th> */}
                    </Tr>
                </Thead>
                <Tbody>
                    {data?.length > 0 ? data?.map((item, i) => (
                        <Tr key={i}>
                            <Td>{i + 1}</Td>
                            <Td>{item?.heading}</Td>
                            <Td><p>{showFirstFewWords(item?.description, 10)}...</p></Td>
                            <Td>
                                <Image boxSize='200px' h={150} w={150} src={item?.image_link} alt={item?.heading} />
                            </Td>
                            <Td>{item?.date_iso}</Td>
                        </Tr>
                    )) : <TableCaption>No Records Found!</TableCaption>}


                </Tbody>

                <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add Event</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody py={8}>
                            <FormControl pb={2}>
                                <FormLabel>Event Name</FormLabel>
                                <Input type='text' placeholder='Enter Event name' value={heading} onChange={(e) => setheading(e.target.value)} />

                            </FormControl>
                            <FormControl pb={2}>
                                <FormLabel>Event Description</FormLabel>
                                <Input type='text' placeholder='Enter Event description' value={description} onChange={(e) => setdescription(e.target.value)} />

                            </FormControl>
                            <FormControl pb={2}>
                                <FormLabel>Event Image</FormLabel>
                                <Input type='file' placeholder='Enter Image url' onChange={handleFileUpload} />

                            </FormControl>
                            <FormControl pb={2}>
                                <FormLabel>Event Date</FormLabel>
                                <Input
                                    placeholder="Select Date and Time"
                                    size="md"
                                    type="datetime-local"
                                    value={event_date} onChange={(e) => setevent_date(e.target.value)}
                                />
                            </FormControl>

                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button variant='ghost' onClick={handleSchoolEvents}>Submit</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Table>
        </>
    )
}

