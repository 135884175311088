import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import { Flex } from '@chakra-ui/react';

const PrivateRoute = ({ component: component, ...rest }) => {
    const admin_token = localStorage.getItem('admin_token');
    console.log({ admin_token })
    return admin_token ? (
        <Flex>
            <Sidebar />
            <Outlet />
        </Flex>
    ) :
        <Navigate to="/login" />;
}

export default PrivateRoute;
