import { Flex, Icon, Link, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import React from 'react';
import NavHoverBox from './NavHoverBox';
import { useNavigate } from 'react-router-dom';


export default function NavItem({ navSize, title, icon,active, to }) {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(to);
      };
    return (
        <Flex
            mt={30}
            flexDir="column"
            w="100%"
            alignItems={navSize == "small" ? "center" : "flex-start"}
        >
            <Menu placement='right'>
                <Link
                    backgroundColor={active && "#AEC8CA"}
                    p={3}
                    borderRadius={8}
                    _hover={{ textDecor: 'none', backgroundColor: "#AEC8CA" }}
                    w={navSize == "large" && "100%"}
                    onClick={handleRedirect}
                >
                    <MenuButton w="100%">
                        <Flex w="full">
                            <Icon as={icon} fontSize={"xl"} color={active ? "#82AAAD": "gray.500"} />
                            <Text ml={5} display={navSize == "small" ? "none": 'flex'}>{title}</Text>
                        </Flex>
                    </MenuButton>
                </Link>
                
            </Menu>

        </Flex>

    )
}