import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Textarea, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ExamListTable from './tables/ExamListTable';


export default function CreateExam() {
    const [token, settoken] = useState('')
    const [namedata, setnamedata] = useState([])
    const [selectedExamName, setselectedExamName] = useState("")
    const [exam_date, setexam_date] = useState('')
    const [classdata, setclassdata] = useState([])
    const [selectedclassName, setselectedclassName] = useState("");
    const [t_marks, sett_marks] = useState("");
    const [p_marks, setp_marks] = useState("");
    const [selectedExamStatus, setselectedExamStatus] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [examname, setexamname] = useState('')

    const handleCreateExam = async () => {

        const token = localStorage.getItem("admin_token");
        if (selectedExamName === '' || exam_date === '' || selectedclassName === ''
            || t_marks === '' || p_marks === '') {
            toast.error("Please Enter All Fields.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else if (isNaN(t_marks) || isNaN(p_marks) || t_marks < 0 || p_marks < 0) {
            toast.error("Marks must be a positive number.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            const data = {
                exam_name_id: selectedExamName && selectedExamName,
                exam_date: exam_date && exam_date,
                standard_id_for_exam: selectedclassName && selectedclassName,
                theory_max_marks: t_marks && t_marks?.toString(),
                practical_max_marks: p_marks && p_marks?.toString(),
                exam_status: selectedExamStatus
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/createExam', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };


        fetch(`https://smb-eta.vercel.app/admin/getExamnames`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setnamedata(data?.data);
                // settotalholidays(data?.totalRecords)
            }).catch(e => console.log(e))

        fetch(`https://smb-eta.vercel.app/admin/getStandardnames`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setclassdata(data?.data);
                // settotalholidays(data?.totalRecords)
            }).catch(e => console.log(e))

    }

    const handleCreateExamName = async () => {

        const token = localStorage.getItem("admin_token");
        if (examname === '') {
            toast.error("Please Enter Exam Name.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {

            const data = {
                exam_name: examname
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/createexamNames', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        }
    }

    return (
        <Container maxW="container.xlg" padding={4} marginBottom={4} overflowY={"auto"}>
            <Flex h="100vh" py={20}>
                <VStack w="30%" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading> Create Exam</Heading>

                    <Button colorScheme='blue' variant='outline' onClick={onOpen} p={2}>
                        Create Exam Name
                    </Button>



                    <FormControl>
                        <FormLabel>Exam Name</FormLabel>
                        {/* <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} /> */}
                        <Select placeholder='Select Exam Name' onChange={(e) => setselectedExamName(e.target.value)}>
                            {namedata && namedata.map(name => (
                                <option value={name?._id} key={name?._id}>{name?.exam_name}</option>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Exam Date</FormLabel>
                        <Input
                            placeholder="Select Date and Time"
                            size="md"
                            type="datetime-local"
                            value={exam_date} onChange={(e) => setexam_date(e.target.value)}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Class</FormLabel>
                        {/* <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} /> */}
                        <Select placeholder='Select Class' onChange={(e) => setselectedclassName(e.target.value)}>
                            {classdata && classdata.map(name => (
                                <option value={name?._id} key={name?._id}>{name?.standard_name}</option>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Theory Marks (Max.)</FormLabel>
                        <Input type='text' placeholder='Enter Theory Marks for exam' value={t_marks} onChange={(e) => sett_marks(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Practical Marks (Max.)<small>(Optional)</small></FormLabel>
                        <Input type='text' placeholder='Enter Practical Marks for exam' value={p_marks} onChange={(e) => setp_marks(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Exam Status</FormLabel>
                        {/* <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} /> */}
                        <Select placeholder='Select Exam Status' onChange={(e) => setselectedExamStatus(e.target.value)}>
                            <option value={"Not Started"}>Not Started</option>
                            <option value={"Started"}>Started</option>
                            <option value={"Completed"}>Completed</option>
                        </Select>
                    </FormControl>

                    <Button colorScheme='teal' variant='outline' onClick={handleCreateExam} p={2}>
                        Create Exam
                    </Button>
                </VStack>
                <VStack bg={'gray.200'} w="full" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading>Exam Listing</Heading>
                    <ExamListTable />
                </VStack>
            </Flex>

            {/* To create New Exam Name */}
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create A Exam Name</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody py={8}>
                        <FormControl pb={2}>
                            <FormLabel>Exam Name</FormLabel>
                            <Input type='text' placeholder='Enter name' value={examname} onChange={(e) => setexamname(e.target.value)} />

                        </FormControl>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={handleCreateExamName}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    )
}