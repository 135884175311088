import { Container, Flex } from '@chakra-ui/react';
import React from 'react';
import ApplicationFormList from './lists/ApllicationFormList';


export default function Home() {
    return (
        <Container maxW={"100%"} padding={4} marginBottom={4}>
            <ApplicationFormList />
        </Container>
    )
}