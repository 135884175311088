import React from 'react';
import {
  ChakraProvider,
  CSSReset,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Login';
import PrivateRoute from './PrivateRoute';
import NotFound from './NotFound';
import CreateStaff from './pages/CreateStaff';
import CreateHoliday from './pages/CreateHoliday';
import CreateExam from './pages/CreateExam';
import CreateClassSection from './pages/CreateClassSection';
import CreateTimeTable from './pages/CreateTimetable';
import CreateSchoolData from './pages/CreateSchoolData';
import CreateTeacher from './pages/CreateTeacher';
import CreateCharacterCertificate from './pages/CreateCharacterCertificate';
import CreateGallery from './pages/CreateGallery';
import Test from './pages/Test';

function App() {
  return (
    <ChakraProvider >
      <Routes>

        <Route path='/login' element={<Login />} />
        <Route exact path='/' element={<PrivateRoute />}>
          <Route path='/' element={<Home />} />
          <Route path='/test' element={<Test />} />
          <Route path='/createStaff' element={<CreateStaff />} />
          <Route path='/createHoliday' element={<CreateHoliday />} />
          <Route path='/createExam' element={<CreateExam />} />
          <Route path='/createClassSection' element={<CreateClassSection />} />
          <Route path='/createTimeTable' element={<CreateTimeTable />} />
          <Route path='/schoolData' element={<CreateSchoolData />} />
          <Route path='/createTeacher' element={<CreateTeacher />} />
          <Route path='/createCharacterCertificate' element={<CreateCharacterCertificate />} />
          <Route path='/createGallery' element={<CreateGallery />} />

        </Route>

        <Route path='*' element={<NotFound />} />

      </Routes>
      <ToastContainer />
    </ChakraProvider>
  );
}

export default App;
