import { Button, Container, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Textarea, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ExamListTable from './tables/ExamListTable';
import ClassSectionListTable from './tables/ClassSectionListTable';
import TimeTableListing from './tables/TimeTableLListing';


export default function CreateTimeTable() {
    const [token, settoken] = useState('')
    const [data, setdata] = useState([])

    const [selectedClassSection, setselectedClassSection] = useState("")
    const [image_file, setimage_file] = useState(null)
    const [image, setimage] = useState('')

    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleCreateTimeTable = async () => {

        const token = localStorage.getItem("admin_token");
        if (selectedClassSection === '' || image_file === null || image === '') {
            toast.error("Please Enter All Fields.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {

            const data = {
                classwithsection_id: selectedClassSection && selectedClassSection,
                timetable_image: image
            }

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': "Bearer " + token,
                },
            };

            fetch('https://smb-eta.vercel.app/admin/createTimeTable', requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    if (data.status === 'ok') {
                        // setLoader(false)
                        toast.success(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        // FetchData()
                    } else {
                        // setLoader(false)
                        toast.warning(data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }).catch(e => console.log(e))
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }

        FetchData();
    }, [])

    async function FetchData() {
        const token = localStorage.getItem("admin_token");
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch(`https://smb-eta.vercel.app/admin/classSectionListing?standard_id=`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setdata(data?.data);
                // settotalrecords(data?.totalRecords)
            }).catch(e => console.log(e))
    }


    const handleChange = async(e) => {
        console.log(e.target.files);
        const file = e.target.files[0];
        setimage_file(URL.createObjectURL(file));
        const base64 = await convertToBase64(file);
        // console.log(base64)
        // setPostImage({ ...postImage, myFile: base64 })
        setimage(base64)
    }

    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    return (
        <Container maxW="container.xlg" padding={4} marginBottom={4}  overflowY={"auto"}>
            <Flex h="100vh" py={20}>
                <VStack w="50%" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading> Create Time table</Heading>

                    <FormControl>
                        <FormLabel>Class & Section</FormLabel>
                        {/* <Input type='text' placeholder='Enter name' value={name} onChange={(e) => setname(e.target.value)} /> */}
                        <Select placeholder='Select Class Section' onChange={(e) => setselectedClassSection(e.target.value)}>
                            {data && data.map(name => (
                                <option value={name?._id} key={name?._id}>{name?.standard_name} {name?.standard_section}</option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Upload TimeTable Image</FormLabel>
                        <Input type='file' onChange={handleChange} />
                        <img src={image_file} />
                    </FormControl>

                    <Button colorScheme='teal' variant='outline' onClick={handleCreateTimeTable} p={2}>
                        Submit
                    </Button>
                </VStack>
                <VStack bg={'gray.200'} w="full" h={"full"} p={10} spacing={10} align={"flex-start"}>
                    <Heading>Time Table Listing</Heading>
                    <TimeTableListing />
                </VStack>
            </Flex>

        </Container>
    )
}