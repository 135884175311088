import React, { useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Avatar,
    Box,
    IconButton,
    Tooltip,
    Image,
    Switch,

} from '@chakra-ui/react'
import 'react-toastify/dist/ReactToastify.css';

export default function HolidayTable({ data}) {

    return (
        <Table variant='striped' colorScheme='' size={'lg'}>
            <Thead>
                <Tr>
                    <Th>S.No</Th>
                    <Th>Name</Th>
                    <Th>Date</Th>
                    {/* <Th>Active Status</Th> */}
                    {/* <Th>Actions</Th> */}
                </Tr>
            </Thead>
            <Tbody>
                {data?.length > 0 ? data?.map((item, i) => (
                    <Tr key={i}>
                        <Td>{i + 1}</Td>
                        <Td>{item?.name}</Td>
                        <Td>{(item?.date).split("T")[0]}</Td>
                        {/* <Td></Td> */}

                    </Tr>
                )) : <TableCaption>No Records Found!</TableCaption>}


            </Tbody>
        </Table>
    )
}

