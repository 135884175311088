import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, SimpleGrid, Button, Text, Heading, Image, Stack, Divider, ButtonGroup, Flex } from '@chakra-ui/react'

export default function GalleryCards({ data }) {
    console.log({ data })

    function showFirstFewWords(str, numWords) {
        // Split the string into an array of words
        var words = str.split(' ');
      
        // Extract the desired number of words
        var extractedWords = words.slice(0, numWords);
      
        // Join the extracted words back into a string
        var result = extractedWords.join(' ');
      
        return result;
      }

    return (
        <>
            <Flex flexWrap={"wrap"} justifyContent={'space-between'}>
                {
                    data?.length > 0 ? data?.map((item, i) => (
                        <Card maxW='sm' width={'30%'} mb={3}>
                            <CardBody>
                                <Image
                                    src={item?.image_link}
                                    alt='Green double couch with wooden legs'
                                    borderRadius='lg'
                                />
                                <Stack mt='6' spacing='3'>
                                    <Heading size='md'>{item?.name}</Heading>
                                    <Text>{showFirstFewWords(item?.description, 10)}...</Text>
                                    <Text color='blue.600' fontSize='sm'>
                                        Date: <span>{(item?.date).split('T')[0]}</span>
                                    </Text>
                                </Stack>
                            </CardBody>
                            {/* <Divider />
                    <CardFooter>
                        <ButtonGroup spacing='2'>
                            <Button variant='solid' colorScheme='blue'>
                                Buy now
                            </Button>
                            <Button variant='ghost' colorScheme='blue'>
                                Add to cart
                            </Button>
                        </ButtonGroup>
                    </CardFooter> */}
                        </Card>
                    )) : <Text>
                        No Records Found!
                    </Text>
                }

            </Flex>
        </>
    )
}