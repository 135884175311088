import React, { useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Avatar,
    Box,
    IconButton,
    Tooltip,
    Image,

} from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckIcon, CloseIcon, ViewIcon } from '@chakra-ui/icons'
import ReactPaginate from 'react-paginate';


export default function ApplicationFormTable({ data, onOpen, handleDataFromChild }) {
    const [token, settoken] = useState('')
    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        if (token) {
            settoken(token);
        }
        else {
            window.location.href = "/login";
        }
    }, [])

    const approveApplication = (e, id) => {
        const data = {
            application_id: id,
            approve: true
        }
        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch(`https://smb-eta.vercel.app/admin/updateapplicationformstatus`, requestOptions)
            .then(resp => resp.json())
            .then(data => {
                toast.success(data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });


            }).catch(e => console.log(e))
    }

    const rejectApplication = (e, id) => {
        const data = {
            application_id: id,
            approve: false
        }
        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Authorization': "Bearer " + token,
            },
        };

        fetch(`https://smb-eta.vercel.app/admin/updateapplicationformstatus`, requestOptions)
            .then(resp => resp.json())
            .then(data => {
                toast.success(data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });


            }).catch(e => console.log(e))
    }
    return (
        <Table variant='striped' colorScheme='' size={'lg'}>
            <ToastContainer />
            <Thead>
                <Tr>
                    <Th>S.No</Th>
                    <Th>Name</Th>
                    <Th>Image</Th>
                    <Th>Adhaar</Th>
                    <Th isNumeric>Mobile</Th>
                    <Th >Status</Th>
                    <Th >View Detail</Th>
                    <Th>Actions</Th>
                </Tr>
            </Thead>
            <Tbody>
                {data?.length > 0 ? data?.map((item, i) => (
                    <Tr key={i}>
                        <Td>{i + 1}</Td>
                        <Td>{item?.name}</Td>
                        <Td><Avatar src={item?.photo} size={'md'} /></Td>
                        <Td>
                            <Image boxSize='200px' h={150} w={150} src={item?.adhaar_image} alt={item?.name} />
                        </Td>
                        <Td isNumeric>{item?.mobile}</Td>
                        <Td >{(item?.isApplicationApproved === false && item?.isApplicationProcessed === false) ? 'Pending' : ((item?.isApplicationApproved === false && item?.isApplicationProcessed === true) ? 'Rejected' : 'Approved')}</Td>
                        <Td>
                            <Tooltip label='View Detail' fontSize='md'>
                                <IconButton
                                    aria-label='Call Segun'
                                    variant={'outline'}
                                    colorScheme='teal'
                                    size='md'
                                    icon={<ViewIcon />}
                                    onClick={onOpen}
                                />
                            </Tooltip>
                        </Td>
                        <Td>
                            {item?.isApplicationProcessed === false ? <>
                                <Tooltip label='Approve' fontSize='md'>
                                    <IconButton
                                        mx={2}
                                        aria-label='Call Segun'
                                        variant={'outline'}
                                        colorScheme='teal'
                                        size='md'
                                        icon={<CheckIcon />}
                                        onClick={(e) => approveApplication(e, item?._id)}
                                    />
                                </Tooltip>

                                <Tooltip label='Decline' fontSize='md'>
                                    <IconButton
                                        aria-label='Call Segun'
                                        variant={'outline'}
                                        colorScheme='red'
                                        size='md'
                                        icon={<CloseIcon />}
                                        onClick={(e) => rejectApplication(e, item?._id)}
                                    />
                                </Tooltip>
                            </> : '-'}


                        </Td>
                    </Tr>
                )) : <TableCaption>No Records Found!</TableCaption>}


            </Tbody>
        </Table>
    )
}